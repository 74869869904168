<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Annonse
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Ved å annonsere i våre publikasjoner vil du presentere selskapet ditt, produktene og tjenestene, til toppsjefer, ledere og beslutningstakere i den norske og globale akvakultur-, fiskeri- og sjømatnæringen.
          </p>
          <p>
            Vi har abonnenter og lesere fra sjømatnæringen i alle verdens hjørner.
          </p>
          <p>
            Ved å forsyne næringen med relevant og tillitsvekkende informasjon når vi involverte lesere som har betydelig kjøpekraft.
          </p>
          <p>
            <a href="https://intrafishno.ocast.com/">IntraFish.no Media kit</a> (norsk)
          </p>
          <!--
          <p>
            <a href="http://epaper.intrafish.com/intrafish/70/">IntraFish.no Media kit</a> (english)
          </p>
          -->
          <p>
            <a href="https://nhst-intrafish.ocast.com/">IntraFish.com Media kit</a> (english)
          </p>
          <p>
            <a href="https://fiskeribladet.ocast.com/">Fiskeribladet Media kit</a> (norsk)
          </p>
          <p>
            For nærmere informasjon <a href="http://tinyurl.com/IFMsales">sales[at]intrafish.com</a>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'advertise-page',
  extends: Page
};
</script>
